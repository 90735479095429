import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';

import SEO from '../../../components/SEO';
import Body from '../../../components/layout/Body';
import ResponsiveContainer from '../../../components/layout/ResponsiveContainer';
import ContentWrapper from '../../../components/layout/ContentWrapper';

import PoliciesNavBar from '../../../components/section/policies/PoliciesNavBar';
import PoliciesContentArea from '../../../components/section/policies/PoliciesContentArea';

import { ViewportBreakpoints } from '../../../components/cssConstants';

import H1 from '@rotaready/frecl/build/H1';
import H2 from '@rotaready/frecl/build/H2';

const TitleWrapper = styled.div`
  text-align: center;
  margin-bottom: 60px;

  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    margin-bottom: 100px;
  }
`;

const BodyWrapper = styled.div`
  display: flex;
  flex-direction: column;

  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    flex-direction: row;
  }
`;

const header = {
  backgroundColor: 'white',
  invertColors: false,
};

const Page = () => (
  <Body header={header}>
    <SEO
      title="Privacy Policy - Rotaready"
      url="policies/privacy-policy"
    />

    <ResponsiveContainer>
      <ContentWrapper>
        <TitleWrapper>
          <H1 uistyle="brand160" text="Privacy Policy" />
        </TitleWrapper>

        <BodyWrapper>
          <PoliciesNavBar />

          <PoliciesContentArea>
            <p><b>Effective date: 21 May 2018</b></p>

            <p>This Privacy Policy describes how Rotaready collects, uses and discloses information, and what
              choices you have with respect to the information.</p>

            <p>Updates in this version of the Privacy Policy reflect changes in data protection law. In addition, we
              have worked to make the Privacy Policy clearer and more understandable by:</p>

            <ul>
              <li>organising it into the sections listed in the Table of Contents below,</li>
              <li>providing a series of examples that help illustrate how the policies may be implemented by Rotaready and</li>
              <li>defining and capitalising a few terms that are used more than once for simplicity and brevity.</li>
            </ul>

            <p>When we refer to "<b>Rotaready</b>", we mean the Rotaready entity that acts as the processor of your
              information, as explained in more detail in the "Identifying the Data Controller and Processor"
              section below.</p>

            <H2 uistyle="brand160" uistyle="brand160">Table of Contents</H2>

            <ul>
              <li><a href="#applicability">Applicability of this Privacy Policy</a></li>
              <li><a href="#collect">Information We Collect and Receive</a></li>
              <li><a href="#information">How We Use Information</a></li>
              <li><a href="#retention">Data Retention</a></li>
              <li><a href="#disclose">How We Share and Disclose Information</a></li>
              <li><a href="#security">Security</a></li>
              <li><a href="#limitations">Age Limitations</a></li>
              <li><a href="#changes">Changes to this Privacy Policy</a></li>
              <li><a href="#dpo">Data Protection Officer</a></li>
              <li><a href="#identifying">Identifying the Data Controller and Processor</a></li>
              <li><a href="#rights">Your Rights</a></li>
              <li><a href="#dpa">Data Protection Authority</a></li>
              <li><a href="#contact">Contacting Rotaready</a></li>
            </ul>

            <H2 uistyle="brand160" id="applicability">Applicability of This Privacy Policy</H2>

            <p>This Privacy Policy applies to Rotaready's online workforce management tools and platform, including
              the associated Rotaready mobile and tablet applications (collectively, the "<b>Services</b>"),
              Rotaready.com and other Rotaready websites (collectively, the "<b>Websites</b>") and other interactions
              (e.g., customer service inquiries, user conferences, etc.) you may have with Rotaready. If you do
              not agree with the terms, do not access or use the Services, Websites or any other aspect of
              Rotaready's business.</p>

            <p>This Privacy Policy does not apply to any third party applications or software that integrate with
              the Services through the Rotaready platform ("<b>Third Party Services</b>"), or any other third party
              products, services or businesses. In addition, a separate agreement governs delivery, access and use
              of the Services (the "<b>Subscription Agreement</b>"), including the processing of any personal data
              submitted through Services accounts (collectively, "<b>Customer Data</b>"). The organisation (e.g., your
              employer or another entity or person) that entered into the Subscription Agreement ("<b>Customer</b>")
              controls their instance of the Services (their "<b>Realm</b>") and any associated Customer Data. If you
              have any questions about specific Realm settings and privacy practices, please contact the Customer
              whose Realm you use.</p>

            <H2 uistyle="brand160" id="collect">Information We Collect And Receive</H2>

            <p>Rotaready may collect and receive Customer Data and other information and data ("<b>Other
              Information</b>") in a variety of ways:</p>

            <ul>
              <li><b>Customer Data.</b> Customers or individuals granted access to a Realm by a Customer ("<b>Authorised
                Users</b>") routinely submit Customer Data to Rotaready when using the Services.
              </li>
              <li><b>Other Information.</b> Rotaready also collects, generates and/or receives Other Information:
                <ul>
                  <li>Realm and Account Information. To create or update a Realm account, you or your Customer
                    (e.g., your employer) supply Rotaready with an email address, phone number, password,
                    domain and/or similar account details. In addition, Customers that purchase a paid
                    version of the Services provide Rotaready (or its payment processors) with billing
                    details such as credit card information, banking information and/or a billing address.
                  </li>
                  <li>Usage Information.
                    <ul>
                      <li>Services Metadata. When an Authorised User interacts with the Services, metadata
                        is generated that provides additional context about the way Authorised Users
                        work. For example, Rotaready logs the people, features, content and links you
                        interact with and what Third Party Services are used (if any).
                      </li>
                      <li>Log data. As with most websites and technology services delivered over the
                        Internet, our servers automatically collect information when you access or use
                        our Websites or Services and record it in log files. This log data may include
                        the Internet Protocol (IP) address, the address of the web page visited before
                        using the Website or Services, browser type and settings, the date and time the
                        Services were used, information about browser configuration and plugins,
                        language preferences and cookie data.
                      </li>
                      <li>Device information. Rotaready collects information about devices accessing the
                        Services, including type of device, what operating system is used, device
                        settings, application IDs, unique device identifiers and crash data. Whether we
                        collect some or all of this Other Information often depends on the type of
                        device used and its settings.
                      </li>
                      <li>Location information. We receive information from you, your Customer and other
                        third-parties that helps us approximate your location. We may, for example, use
                        a business address submitted by your employer, or an IP address received from
                        your browser or device to determine approximate location. Rotaready may also
                        collect location information from devices in accordance with the consent process
                        provided by your device.
                      </li>
                    </ul>
                  </li>
                  <li>Cookie Information. Rotaready uses cookies and similar technologies in our Websites and
                    Services that help us collect Other Information. The Websites and Services may also
                    include cookies and similar tracking technologies of third parties, which may collect
                    Other Information about you via the Websites and Services and across other websites and
                    online services.
                  </li>
                  <li>Third Party Services. Customer can choose to permit or restrict Third Party Services for
                    their Realm. Typically, Third Party Services are software that integrate with our
                    Services, and Customer can permit its Authorised Users to enable and disable these
                    integrations for their Realm. Once enabled, the provider of a Third Party Service may
                    share certain information with Rotaready. For example, if an HR application is enabled
                    to permit absence records to be imported to a Realm, we may receive names and time off
                    data of Authorised Users, along with additional information that the application has
                    elected to make available to Rotaready to facilitate the integration. Authorised Users
                    should check the privacy settings and notices in these Third Party Services to
                    understand what data may be disclosed to Rotaready. When a Third Party Service is
                    enabled, Rotaready is authorised to connect and access Other Information made available
                    to Rotaready in accordance with our agreement with the Third Party Provider. We do not,
                    however, receive or store passwords for any of these Third Party Services when
                    connecting them to the Services.
                  </li>
                  <li>Contact Information. In accordance with the consent process provided by your device, any
                    contact information that an Authorised User chooses to import (such as an address book
                    from a device) is collected when using the Services.
                  </li>
                  <li>Third Party Data. Rotaready may receive data about organisations, industries, Website
                    visitors, marketing campaigns and other matters related to our business from parent
                    corporation(s), affiliates and subsidiaries, our partners or others that we use to make
                    our own information better or more useful. This data may be combined with Other
                    Information we collect and might include aggregate level data, such as which IP
                    addresses correspond to postal codes or countries. Or it might be more specific: for
                    example, how well an online marketing or email campaign performed.
                  </li>
                  <li>Additional Information Provided to Rotaready. We receive Other Information when
                    submitted to our Websites or if you participate in a focus group, contest, activity or
                    event, apply for a job, request support, interact with our social media accounts or
                    otherwise communicate with Rotaready.
                  </li>
                </ul>
              </li>
            </ul>

            <p>Generally, no one is under a statutory or contractual obligation to provide any Customer Data or
              Other Information (collectively, "Information"). However, certain Information is collected
              automatically and, if some Information, such as Realm setup details, is not provided, we may be
              unable to provide the Services.</p>

            <H2 uistyle="brand160" id="information">How We Use Information</H2>

            <p>Customer Data will be used by Rotaready in accordance with Customer's instructions, including any
              applicable terms in the Subscription Agreement and Customer's use of Services functionality, and as
              required by applicable law. Rotaready is a processor of Customer Data and Customer is the
              controller. Customer may, for example, use the Services to grant and remove access to a Realm,
              assign roles and configure settings, access, modify, export, share and remove Customer Data and
              otherwise apply its policies to the Services.</p>

            <p>Rotaready uses Other Information in furtherance of our legitimate interests in operating our
              Services, Websites and business. More specifically, Rotaready uses Other Information:</p>

            <ul>
              <li><b>To provide, update, maintain and protect our Services, Websites and business.</b> This
                includes use of Other Information to support delivery of the Services under a Subscription
                Agreement, prevent or address service errors, security or technical issues, analyse and monitor
                usage, trends and other activities or at an Authorised User's request.
              </li>
              <li><b>As required by applicable law, legal process or regulation.</b></li>
              <li><b>To communicate with you by responding to your requests, comments and questions.</b> If you
                contact us, we may use your Other Information to respond.
              </li>
              <li><b>To develop and provide search, learning and productivity tools and additional features.</b>
                Rotaready tries to make the Services as useful as possible for specific Realms and Authorised
                Users. For example, we may improve search functionality by using Other Information to help
                determine and rank the relevance of content, channels or expertise to an Authorised User, make
                Services suggestions based on historical use and predictive models, identify organisational
                trends and insights, to customise a Services experience or create new productivity features and
                products.
              </li>
              <li><b>To send emails and other communications.</b> We may send you service, technical and other
                administrative emails, messages and other types of communications. We may also contact you to
                inform you about changes in our Services, our Services offerings, and important Services-related
                notices, such as security and fraud notices. These communications are considered part of the
                Services and you may not opt out of them. In addition, we sometimes send emails about new
                product features, promotional communications or other news about Rotaready. These are marketing
                messages so you can control whether you receive them.
              </li>
              <li><b>For billing, account management and other administrative matters.</b> Rotaready may need to
                contact you for invoicing, account management and similar reasons and we use account data to
                administer accounts and keep track of billing and payments.
              </li>
              <li><b>To investigate and help prevent security issues and abuse.</b></li>
            </ul>

            <p>If Information is aggregated or de-identified so it is no longer reasonably associated with an
              identified or identifiable natural person, Rotaready may use it for any business purpose. To the
              extent Information is associated with an identified or identifiable natural person and is protected
              as personal data under applicable data protection law, it is referred to in this Privacy Policy as "<b>Personal
                Data.</b>"</p>

            <H2 uistyle="brand160" id="retention">Data Retention</H2>

            <p>Rotaready will retain Customer Data in accordance with a Customer's instructions, including any
              applicable terms in the Subscription Agreement and Customer's use of Services functionality, and as
              required by applicable law. Depending on the Services plan, Customer may be able to customise its
              retention settings and apply those customised settings at the Realm level. The deletion of Customer
              Data and other use of the Services by Customer may result in the deletion and/or de-identification
              of certain associated Other Information. Rotaready may retain Other Information pertaining to you
              for as long as necessary for the purposes described in this Privacy Policy. This may include keeping
              your Other Information after you have deactivated your account for the period of time needed for
              Rotaready to pursue legitimate business interests, conduct audits, comply with (and demonstrate
              compliance with) legal obligations, resolve disputes and enforce our agreements.</p>

            <H2 uistyle="brand160" id="disclose">How We Share And Disclose Information</H2>

            <p>This section describes how Rotaready may share and disclose Information. Customers determine their
              own policies and practices for the sharing and disclosure of Information, and Rotaready does not
              control how they or any other third parties choose to share or disclose Information.</p>

            <ul>
              <li><b>Customer's Instructions.</b> Rotaready will solely share and disclose Customer Data in
                accordance with a Customer's instructions, including any applicable terms in the Subscription
                Agreement and Customer's use of Services functionality, and in compliance with applicable law
                and legal process.
              </li>
              <li><b>Displaying the Services.</b> When an Authorised User submits Other Information, it may be
                displayed to other Authorised Users in the same Realm. For example, an Authorised User's email
                address may be displayed with their Realm profile.
              </li>
              <li><b>Customer Access.</b> Owners, administrators, Authorised Users and other Customer
                representatives and personnel may be able to access, modify or restrict access to Other
                Information. This may include, for example, your employer using Service features to export logs
                of Realm activity, or accessing or modifying your profile details.
              </li>
              <li><b>Third Party Service Providers and Partners.</b> We may engage third party companies or
                individuals as service providers or business partners to process Other Information and support
                our business. These third parties may, for example, provide virtual computing and storage
                services. Additional information about the sub-processors we use to support delivery of our
                Services is set forth in at <Link to="/policies/subprocessors">Subprocessors</Link>.
              </li>
              <li><b>Third Party Services.</b> Customer may enable or permit Authorised Users to enable Third
                Party Services. When enabled, Rotaready may share Other Information with Third Party Services.
                Third Party Services are not owned or controlled by Rotaready and third parties that have been
                granted access to Other Information may have their own policies and practices for its collection
                and use. Please check the privacy settings and notices in these Third Party Services or contact
                the provider for any questions.
              </li>
              <li><b>Corporate Affiliates.</b> Rotaready may share Other Information with its corporate
                affiliates, parents and/or subsidiaries.
              </li>
              <li><b>During a Change to Rotaready's Business.</b> If Rotaready engages in a merger, acquisition,
                bankruptcy, dissolution, reorganisation, sale of some or all of Rotaready's assets or stock,
                financing, public offering of securities, acquisition of all or a portion of our business, a
                similar transaction or proceeding, or steps in contemplation of such activities (e.g. due
                diligence), some or all Other Information may be shared or transferred, subject to standard
                confidentiality arrangements.
              </li>
              <li><b>Aggregated or De-identified Data.</b> We may disclose or use aggregated or de-identified
                Other Information for any purpose. For example, we may share aggregated or de-identified Other
                Information with prospects or partners for business or research purposes, such as telling a
                prospective Rotaready customer the average amount of time spent within a typical Realm.
              </li>
              <li><b>To Comply with Laws.</b> If we receive a request for information, we may disclose Other
                Information if we reasonably believe disclosure is in accordance with or required by any
                applicable law, regulation or legal process.
              </li>
              <li><b>To enforce our rights, prevent fraud, and for safety.</b> To protect and defend the rights,
                property or safety of Rotaready or third parties, including enforcing contracts or policies, or
                in connection with investigating and preventing fraud or security issues.
              </li>
              <li><b>With Consent.</b> Rotaready may share Other Information with third parties when we have
                consent to do so.
              </li>
            </ul>

            <H2 uistyle="brand160" id="security">Security</H2>

            <p>Rotaready takes security of data very seriously. Rotaready works hard to protect Personal Data and
              Other Information you provide from loss, misuse, and unauthorised access or disclosure. These steps
              take into account the sensitivity of the Personal Data and Other Information we collect, process and
              store, and the current state of technology. We employ industry-grade encryption during transmission
              and while data is at rest. Given the nature of communications and information processing technology,
              Rotaready cannot guarantee that Information, during transmission through the Internet or while
              stored on our systems or otherwise in our care, will be absolutely safe from intrusion by
              others.</p>

            <H2 uistyle="brand160" id="limitations">Age Limitations</H2>

            <p>To the extent prohibited by applicable law, Rotaready does not allow use of our Services and Websites
              by anyone younger than 13 years old. If you learn that anyone younger than this age has unlawfully
              provided us with Personal Data, please contact us and we will takes steps to delete such
              information.</p>

            <H2 uistyle="brand160" id="changes">Changes To This Privacy Policy</H2>

            <p>Rotaready may change this Privacy Policy from time to time. Laws, regulations and industry standards
              evolve, which may make those changes necessary, or we may make changes to our business. We will post
              the changes to this page and encourage you to review our Privacy Policy to stay informed. If we make
              changes that materially alter your privacy rights, Rotaready will provide additional notice, such as
              via email or through the Services. If you disagree with the changes to this Privacy Policy, you
              should deactivate your Services account. Contact us and the Customer if you wish to request the
              removal of Personal Data under their control.</p>

            <H2 uistyle="brand160" id="dpo">Data Protection Officer</H2>

            <p>To communicate with our Data Protection Officer, please email <a href="mailto:team@rotaready.com">team@rotaready.com</a>.</p>

            <H2 uistyle="brand160" id="identifying">Identifying The Data Controller And Processor</H2>

            <p>Data protection law in certain jurisdictions differentiates between the "controller" and "processor"
              of information. In general, Customer is the controller of Customer Data. In general, Rotaready is
              the processor of Customer Data and the controller of Other Information.</p>

            <H2 uistyle="brand160" id="rights">Your Rights</H2>

            <p>Individuals located in certain countries, including the European Economic Area, have certain
              statutory rights in relation to their Personal Data. Subject to any exemptions provided by law, you
              may have the right to request access to Information, as well as to seek to update, delete or correct
              this Information. You can sometimes do this using the settings and tools provided in your Services
              account. If you cannot use the settings and tools, contact Customer for additional access and
              assistance.</p>

            <p>To the extent that Rotaready's processing of your Personal Data is subject to the General Data
              Protection Regulation, Rotaready relies on its legitimate interests, described above, to process
              your data. Rotaready may also process Other Information that constitutes your Personal Data for
              direct marketing purposes and you have a right to object to Rotaready's use of your Personal Data
              for this purpose at any time.</p>

            <H2 uistyle="brand160" id="dpa">Data Protection Authority</H2>

            <p>Subject to applicable law, you also have the right to (i) restrict Rotaready's use of Other
              Information that constitutes your Personal Data and (ii) lodge a complaint with your local data
              protection authority or the Information Commissioner's Office (ICO), which is Rotaready's lead
              supervisory authority in the European Union. If you are a resident of the European Economic Area and
              believe we maintain your Personal Data within the scope of the General Data Protection Regulation
              (GDPR), you may direct questions or complaints to our lead supervisory authority:</p>

            <p>Information Commissioner's Office<br />
              Wycliffe House<br />
              Water Lane<br />
              Wilmslow<br />
              Cheshire<br />
              SK9 5AF<br />
              United Kingdom</p>

            <p>Tel: +44 (0) 1625 545745<br />
              Email: <a href="mailto:casework@ico.org.uk">casework@ico.org.uk</a><br />
              Rotaready's ICO ​Registration ​Number: ZA297859</p>

            <H2 uistyle="brand160" id="contact">Contacting Rotaready</H2>

            <p>Please also feel free to contact Rotaready if you have any questions about this Privacy Policy or
              Rotaready's practices, or if you are seeking to exercise any of your statutory rights. You may
              contact us at <a href="mailto:team@rotaready.com">team@rotaready.com</a> or at our mailing address below:</p>

            <p>
              Rotaready Ltd
              <br />
              1 Hudson's Place
              <br />
              Victoria Station
              <br />
              London
              <br />
              SW1V 1JT
              <br />
              United Kingdom
            </p>
          </PoliciesContentArea>
        </BodyWrapper>
      </ContentWrapper>
    </ResponsiveContainer>
  </Body>
);

export default Page;
